import { Component, OnInit, AfterViewInit, Renderer2, ViewChild, ElementRef } from '@angular/core';
import { fromEvent } from 'rxjs';
import { throttleTime, map, pairwise, share, distinctUntilChanged, filter, tap } from 'rxjs/operators';

enum ElementViewPosition {
  Banner = 'Banner',
  BelowHeader = 'BelowHeader'
}

@Component({
  selector: 'app-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.scss']
})
export class MainNavComponent {

  visible = true;
  menuOpen = false;

  scroll(idName: string) {
    const el = document.getElementById(idName);
    el.scrollIntoView({behavior: 'smooth'});
  }

  toggleMenu(toggle: boolean) {
    this.menuOpen = toggle;
  }
}
