<nav class="main-nav" [ngClass]="{'visible-menu': visible}" #mainNav>
  <div class="main-nav_logo">
    <div (click)="scroll('baner')">
      <img src="assets/images/logo.svg" alt="logo"/>
      <span>SGBB</span>
    </div>
  </div>
  <span class="u-spacer"></span>
  <div class="main-nav_list">
    <span class="material-icons menu-icon" (click)="toggleMenu(true)">
      menu
    </span>
    <ul class="main-manu"  [ngClass]="{'menu-close': !menuOpen}">
      <li>
        <p (click)="scroll('about')" (click)="toggleMenu(false)">O nas</p>
      </li>
      <li>
        <p (click)="scroll('dig-process')" (click)="toggleMenu(false)">Proces kopania</p>
      </li>
      <li>
        <p (click)="scroll('offer')" (click)="toggleMenu(false)">Oferta</p>
      </li>
      <li>
        <p (click)="scroll('equipment')" (click)="toggleMenu(false)">Sprzęt i realizacje</p>
      </li>
      <li>
        <p (click)="scroll('contact')" (click)="toggleMenu(false)">Kontakt</p>
      </li>
    </ul>
  </div>
</nav>
<div class="overlayer" [ngClass]="{'u-display-none': !menuOpen}" (click)="toggleMenu(false)">
</div>
