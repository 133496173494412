<section class="footer-container" id="contact">
  <nav class="footer-nav">
    <h2 (click)="scroll('baner')">SGBB</h2>
    <ul>
      <li>
        <p (click)="scroll('about')">O nas</p>
      </li>
      <li>
        <p (click)="scroll('dig-process')">Proces kopania</p>
      </li>
      <li>
        <p (click)="scroll('offer')">Oferta</p>
      </li>
      <li>
        <p (click)="scroll('equipment')">Sprzęt i realizacje</p>
      </li>
      <li>
        <p (click)="scroll('contact')">Kontakt</p>
      </li>
    </ul>
  </nav>
  <div class="map-image">
    <img src="assets/images/footer/map.svg" alt="mapa"/>
  </div>
  <div class="contact-wapper">
    <h3>Skontaktuj się z nami</h3>
    <div>
      <i class="material-icons">
        face
      </i>
      <p>Krzysztof Bąk</p>
    </div>
    <div>
      <i class="material-icons">
        email
      </i>
      <a href="mailto:sgbielsko@gmail.com">sgbielsko@gmail.com</a>
    </div>
    <div>
      <i class="material-icons">
        watch_later
      </i>
      <p>godziny otwarcia: pon-sob: 8:00 - 20:00
      </p>
    </div>
    <div>
      <i class="material-icons">
        phone
      </i>
      <p>+48 781 855 146</p>
    </div>
    <div>
      <a href="https://www.facebook.com/SGBB-Krzysztof-Bąk-102178941859699">
        <i class="material-icons facebook">
          facebook
        </i>
      </a>
      <a href="https://www.facebook.com/SGBB-Krzysztof-Bąk-102178941859699">nasz facebook</a>
    </div>
  </div>
</section>
<section class="copyrigh">
  Created by slawomir.dev
</section>
